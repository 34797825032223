import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default props => (
  <Layout {...props}>
    <Seo title='Chi siamo' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Chi siamo"/>
        <Divider />
        <p>Siamo un gruppo di appassionati riguardo tutto ciò che gira attorno al mondo delle criptovalute e delle valute digitali in genere.</p>
     </Main>
    </Stack>
  </Layout>
)
